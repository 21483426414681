<template>
  <div>
    <v-form ref="form">
      <v-text-field
        v-model="userData.document"
        outlined
        label="Documento pessoal *"
        :rules="[$rules.required]"
        hint="RG ou documento relativo ao país onde mora"
        persistent-hint
      />
      <v-text-field v-model="userData.occupation" outlined label="Ocupação" />
      <input-date
        v-model="userData.birthDate"
        outlined
        label="Data de nascimento *"
        :rules="[$rules.required, $rules.date]"
      />
      <v-text-field
        v-model="userData.phone"
        outlined
        label="Telefone de contato *"
        :rules="[$rules.required]"
      />
      <v-text-field
        v-model="userData.emergencyPhone"
        outlined
        label="Telefone para emergências"
      />
      <v-select
        v-model="userData.maritalStatusId"
        outlined
        label="Estado civil"
        item-text="name"
        item-value="id"
        :items="maritalStatus"
      />
      <address-form v-model="userData.address" />
      <v-btn color="secondary" large block @click="save">
        {{ buttonText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { findAllActive } from "@/services/external/marital-status-service";

export default {
  name: "FormUserData",
  components: {
    AddressForm: () => import("./FormAddress"),
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    userData: {
      address: {},
    },
    maritalStatus: [],
  }),
  watch: {
    value() {
      this.updateLocalObject();
    },
  },
  created() {
    this.updateLocalObject();
    this.loadMaritalStatus();
  },
  methods: {
    save() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$emit("submit", {
          ...this.userData,
          ...this.userData.address,
        });
      }
    },
    updateLocalObject() {
      if (this.value?.document) {
        this.userData = this.value;
      }

      if (this.value?.city) {
        this.userData.address = {
          zipCode: this.value.zipCode,
          street: this.value.street,
          neighborhood: this.value.neighborhood,
          additional: this.value.additional,
          city: this.value.city,
          state: this.value.state,
          country: this.value.country,
        };
      }
    },
    async loadMaritalStatus() {
      const { data } = await findAllActive();
      this.maritalStatus = data;
    },
  },
};
</script>

<style scoped></style>
